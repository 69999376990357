export default {
  access_denied: 'Přístup byl zamítnut',
  validations: {
    email: 'Zadejte validní e-mail ve formátu "jan@sparta.cz"',
    emailIsUsed: 'Tento e-mail je již použitý',
    emailNotRegistered: 'Tento e-mail není registrován',
    required: 'Toto pole je povinné',
    emojiNotAllowed: 'Emoji nejsou povoleny',
    maxLength: 'Maximální počet znaků je: #{value}',
    minLength: 'Minimální počet znaků je: #{value}',
    maxValue: 'Maximální hodnota je: #{value}',
    minValue: 'Minimální hodnota je: #{value}',
    invalidDate: 'Zadané datum není platné',
    invalidPhone: 'Zadané tel. číslo není validní',
    minAge: 'Registrace není určena osobám mladším #{value} let',
    passwordsDontMatch: 'Hesla se neshodují',
    passwordRequirements: 'Heslo musí obsahovat alespoň jedno velké písmeno, jedno malé písmeno a jedno číslo',
  },
  errors: {
    pageNotFound: 'Stránka, kterou se snažíte zobrazit, nejspíš neexistuje, nebo máte chybný odkaz.',
    codeNotValid: 'Kód není validní nebo byl již použit.',
    invalidPassword: 'Heslo není validní',
  },
  pages: {
    common: {
      metaTitle: 'Připojte se | SPARTA iD',
      metaDescription: 'SPARTA iD | Vaše digitální vstupenka do světa Sparty.',
      goToSpartaId: 'POKRAČOVAT DO SPARTA iD',
      goToSparta: 'Přejít na Sparta.cz',
      or: 'nebo',
      and: 'a',
      close: 'Zavřít',
      others: 'Ostatní',
      notifications: {
        title: 'Notifikace',
        errors: {
          generic: 'Je nám líto, něco se pokazilo. Prosím zkuste to znovu později.',
        },
      },
      alternativeSignIns: 'Alternativní způsoby přihlášení',
      mainNavigation: 'Hlavní navigace',
      previousStep: 'Předchozí krok',
      selectLanguage: 'Vyberte jazyk zobrazení',
      socialNetworks: 'Sociální sítě',
      spartaProclamations: 'Prohlášení Sparta.cz',
      footer: {
        onlinePaymentTerms: 'Podmínky online platby',
        terms: 'Podmínky užití',
        privacyPolicy: 'Ochrana soukromí',
        businessTerms: 'Obchodní podmínky',
        termsSpartaId: 'Podmínky SPARTA iD',
        cookies: 'Cookies',
        accessibility: 'Přístupnost',
        hint: 'Položky označené hvězdičkou jsou povinné',
        copy: 'Copyright © #{year} AC Sparta Praha. Všechna práva vyhrazena.',
      },
      showPassword: 'Ukázat heslo',
      hidePassword: 'Skrýt heslo',
      termUrls: {
        clientDataUrl:
          'https://1742741114.rsc.cdn77.org/files/2024/07/04/Informace%20o%20zpracova%CC%81ni%CC%81%20osobni%CC%81ch%20u%CC%81daju%CC%8A%20pro%20marketingove%CC%81%20u%CC%81c%CC%8Cely%20na%20za%CC%81klade%CC%8C%20souhlasu.pdf',
        businessTermsUrl: 'https://sparta.cz/cs/paticka/podminky-sparta-id',
        personalDataProcessing:
          'https://1742741114.rsc.cdn77.org/files/2024/07/04/Informace%20o%20zpracova%CC%81ni%CC%81%20osobni%CC%81ch%20u%CC%81daju%CC%8A%20uz%CC%8Civatelu%CC%8A%20SPARTA%20iD%20a%20navazuji%CC%81ci%CC%81ch%20sluz%CC%8Ceb.pdf',
      },
    },
    verify: {
      metaTitle: 'Ověření e-mailu | SPARTA iD',
      title: 'Ověření e-mailu',
      verifiedTitle: 'Registrace dokončena',
      description: 'Registraci účtu dokončíte kliknutím na aktivační odkaz, který naleznete v emailu: ',
      verifiedDescription: 'Účet byl úspěšně aktivován',
      alreadyRegistered: 'Máte dokončenou registraci?',
      info: {
        checkFilesAs: 'Zkontrolujte složky jako',
        mass: 'Hromadné',
        spam: 'Spam',
      },
      form: {
        submit: 'Zaslat verifikační e-mail',
        sendAgain: 'Zaslat znovu',
      },
      notifications: {
        success: {
          verified: 'Účet byl úspěšně aktivován',
          mailSent: 'E-mail byl úspěšně odeslán',
        },
      },
    },
    authorize: {
      metaTitle: 'Připojte se | SPARTA iD',
      noSpartId: 'Ještě nemáte SPARTA iD?',
      signUp: 'Vytvořit účet',
      forgottenPassword: 'Zapomenuté heslo?',
      form: {
        placeholders: {
          password: 'Heslo',
        },
        continue: 'Pokračovat',
        signIn: 'Přihlásit se',
      },
    },
    register: {
      metaTitle: 'Registrace | SPARTA iD',
      continueThrough: 'Pokračovat přes',
      haveSpartId: 'Už máte SPARTA iD?',
      signIn: 'Přihlaste se',
      ageInfo:
        'Registrací potvrzujete, že jste starší 15-ti let. V případě nepravdivého potvrzení o dosažení tohoto věkového limitu jsme oprávněni takto registrovaný účet zrušit.',
      form: {
        placeholders: {
          firstName: 'Jméno',
          lastName: 'Příjmení',
          password: 'Heslo',
          passwordAgain: 'Heslo znovu',
          phoneNumber: 'Telefonní číslo',
          phoneNumberPrefix: 'Předvolba',
          gender: 'Pohlaví',
          favouritePlayer: 'Oblíbený sparťan',
          day: 'Den',
          month: 'Měsíc',
          year: 'Rok',
        },
        gender: {
          male: 'Muž',
          female: 'Žena',
        },
        marketing: {
          text: 'Chci dostávat novinky o akcích a speciálních nabídkách partnerů a',
          terms: 'souhlasím se zpracováním osobních údajů',
        },
        terms: {
          iAgreeWith: 'Souhlasím s',
          conditions: 'obchodními podmínkami',
          andAgreeWith: 'a beru na vědomí poučení o',
          dataProcessing: 'zpracování osobních údajů',
        },
        saveAndContinue: 'Uložit a pokračovat',
        finishRegistration: 'Dokončit registraci',
      },
    },
    password: {
      metaTitle: 'Obnova hesla | SPARTA iD',
      title: 'Obnova hesla',
      perex: 'Obnova přístupu k účtu proběhne kliknutím na odkaz, který zašleme na zadaný email: ',
      form: {
        placeholders: {
          newPassword: 'Nové heslo',
          newPasswordAgain: 'Nové heslo znovu',
        },
        submit: 'Nastavit nové heslo',
        request: 'Zaslat ověřovací e-mail',
      },
      notifications: {
        success: {
          mailSent: 'E-mail byl úspěšně odeslán',
          passwordChanged: 'Heslo bylo úspěšně změněno',
        },
        error: {
          recovery_code_not_found: 'Ověřovací kód nebyl nalezen nebo již byl použit',
        },
      },
      rememberPassword: 'Vzpomněli jste si na heslo?',
      signIn: 'Přihlaste se',
      proceedToSignIn: 'Přejít na přihlášení',
    },
    consent: {
      metaTitle: 'Připojte se | SPARTA iD',
      title: 'Přihlášení do služby',
      requires: {
        title: 'vyžaduje pro pokračování:',
        fullName: 'Jméno a příjmení',
        email: 'Emailová adresa',
        photo: 'Profilová fotka',
      },
      form: {
        continue: 'Pokračovat',
        cancel: 'NESOUHLASÍM, PŘEJÍT DO SPARTA iD',
      },
      terms: {
        byContinuing: 'Tato služba získá přístup k vámi sdíleným informacím. Pokračováním souhlasíte s ',
        terms: 'Obchodními podmínkami',
        andAcknowledge: 'a berete na vědomí poučení o ',
        dataProcessing: 'Zpracování osobních údajů',
      },
    },
    notFound: {
      title: 'Tuto stránku jste netrefili',
      message: 'Stránka, kterou se snažíte zobrazit, nejspíš neexistuje, nebo máte chybný odkaz.',
    },
    ssoAlreadyUsed: {
      title: 'E-mail se již používá',
      message: 'Přihlášení se nezdařilo, protože účet s touto e-mailovou adresou již existuje:',
      help: 'Propojení účtů je možné provést později v nastavení SPARTA iD',
      goToSignIn: 'Přejít na přihlášení',
    },
  },
} as const;
