export default {
  access_denied: 'Access denied',
  validations: {
    email: 'Please enter a valid e-mail address',
    emailIsUsed: 'This e-mail address is already in use',
    emailNotRegistered: 'This e-mail is not registered',
    required: 'This field is required',
    emojiNotAllowed: 'Emoji are not allowed',
    maxLength: 'Maximum character count is: #{value}',
    minLength: 'Minimum character count is: #{value}',
    maxValue: 'Maximum value is: #{value}',
    minValue: 'Minimum value is: #{value}',
    invalidDate: 'Please enter a valid date',
    invalidPhone: 'Please enter a valid phone number',
    minAge: 'Registration is not available for people younger than #{value} years',
    passwordsDontMatch: 'Passwords do not match',
    passwordRequirements: 'Password must contain at least one uppercase letter, one lowercase letter and one number',
  },
  errors: {
    pageNotFound: 'The page you are trying to access does not exist or you have a broken link.',
    codeNotValid: 'Code is invalid or was already used.',
    invalidPassword: 'Invalid credentials',
  },
  pages: {
    common: {
      metaTitle: 'Join us | SPARTA iD',
      metaDescription: 'SPARTA iD | Your digital ticket to the world of Sparta.',
      goToSpartaId: 'CONTINUE TO SPARTA iD',
      goToSparta: 'Go to Sparta.cz',
      or: 'or',
      and: 'and',
      close: 'Close',
      others: 'Others',
      notifications: {
        title: 'Notifications',
        errors: {
          generic: "We're sorry, something went wrong. Please try again later.",
        },
      },
      alternativeSignIns: 'Alternative sign in options',
      mainNavigation: 'Main navigation',
      previousStep: 'Previous step',
      selectLanguage: 'Select a language',
      socialNetworks: 'Social networks',
      spartaProclamations: 'Statements of Sparta.cz',
      footer: {
        onlinePaymentTerms: 'Online Payment Terms',
        terms: 'Terms of Use',
        privacyPolicy: 'Privacy Policy',
        businessTerms: 'Business Terms',
        termsSpartaId: 'SPARTA iD Terms',
        cookies: 'Cookies',
        accessibility: 'Accessibility',
        hint: 'Items marked with an asterisk are mandatory',
        copy: 'Copyright © #{year} AC Sparta Prague. All rights reserved.',
      },
      showPassword: 'Show password',
      hidePassword: 'Hide password',
      termUrls: {
        clientDataUrl:
          'https://1742741114.rsc.cdn77.org/files/2024/07/04/Information%20on%20processing%20of%20personal%20data%20for%20marketing%20purposes%20based%20on%20consent.pdf',
        businessTermsUrl: 'https://sparta.cz/en/paticka/sparta-id-terms',
        personalDataProcessing:
          'https://1742741114.rsc.cdn77.org/files/2024/07/04/Information%20on%20personal%20data%20processing%20users%20of%20SPARTA%20iD%20and%20related%20services.pdf',
      },
    },
    verify: {
      metaTitle: 'E-mail verification | SPARTA iD',
      metaDescription: 'E-mail verification',
      title: 'E-mail verification',
      verifiedTitle: 'Registration completed',
      description: 'Complete the account registration by clicking on the activation link found in the email: ',
      verifiedDescription: 'Your account has been successfully activated',
      alreadyRegistered: 'Already Signed up?',
      info: {
        checkFilesAs: 'Check folders such as',
        mass: 'Mass',
        spam: 'Spam',
      },
      form: {
        submit: 'Send verification e-mail',
        sendAgain: 'Send again',
      },
      notifications: {
        success: {
          verified: 'Account was successfully activated',
          mailSent: 'E-mail was sent successfully',
        },
      },
    },
    authorize: {
      metaTitle: 'Join us | SPARTA iD',
      noSpartId: 'No SPARTA iD yet?',
      signUp: 'Sign up',
      forgottenPassword: 'Forgotten password?',
      form: {
        placeholders: {
          password: 'Password',
        },
        continue: 'Continue',
        signIn: 'Sign in',
      },
    },
    register: {
      metaTitle: 'Register | SPARTA iD',
      continueThrough: 'Continue with',
      haveSpartId: 'Already have SPARTA iD?',
      signIn: 'Sign in',
      ageInfo:
        'By registering you confirm that you are over 15 years of age. In the event of false confirmation that you have reached this age limit, we are entitled to cancel the registered account.',
      form: {
        placeholders: {
          firstName: 'First name',
          lastName: 'Last name',
          password: 'Password',
          passwordAgain: 'Password again',
          phoneNumber: 'Phone number',
          phoneNumberPrefix: 'Prefix',
          gender: 'Gender',
          favouritePlayer: 'Favorite Sparta player',
          day: 'Day',
          month: 'Month',
          year: 'Year',
        },
        gender: {
          male: 'Male',
          female: 'Female',
        },
        marketing: {
          text: 'I would like to receive updates on promotions and special offers from partners and',
          terms: 'I agree to the processing of personal data',
        },
        terms: {
          iAgreeWith: 'I agree with',
          conditions: 'terms and conditions',
          andAgreeWith: 'and acknowledge the information on the',
          dataProcessing: 'processing of personal data',
        },
        saveAndContinue: 'Save and continue',
        finishRegistration: 'Finish registration',
      },
    },
    password: {
      metaTitle: 'Forgotten password | SPARTA iD',
      title: 'Forgotten password',
      perex: 'To recover access to your account, click on the link we will send to the specified email: ',
      form: {
        placeholders: {
          newPassword: 'New password',
          newPasswordAgain: 'New password again',
        },
        submit: 'Change password',
        request: 'Send verification e-mail',
      },
      rememberPassword: 'Remembered your password?',
      signIn: 'Sign in',
      notifications: {
        success: {
          mailSent: 'E-mail was sent successfully',
          passwordChanged: 'Password was successfully changed',
        },
        error: {
          recovery_code_not_found: 'Recovery code not found or already used',
        },
      },
      proceedToSignIn: 'Proceed to Sign in',
    },
    consent: {
      metaTitle: 'Join us | SPARTA iD',
      title: 'Sign in to service',
      requires: {
        title: 'requires following data to continue:',
        fullName: 'Full name',
        email: 'E-mail address',
        photo: 'Profile photo',
      },
      form: {
        continue: 'Continue',
        cancel: 'I DISAGREE, GO TO SPARTA iD',
      },
      terms: {
        byContinuing: 'This service gets access to the information you share. By continuing you agree to ',
        terms: 'Terms and Conditions',
        andAcknowledge: 'and you acknowledge the instructions on ',
        dataProcessing: 'Processing of personal data',
      },
    },
    notFound: {
      title: 'This page was a miss',
      message: "The page you're trying to view probably doesn't exist, or you have the wrong link.",
    },
    ssoAlreadyUsed: {
      title: 'E-mail already in use',
      message: 'Sign in failed because an account with this email address already exists:',
      help: 'Linking accounts can be done later in SPARTA iD settings',
      goToSignIn: 'Go to Sign in',
    },
  },
} as const;
